// This file is used to resolve dynamic configuration values at runtime.
type DeploymentEnv = 'prod' | 'stage';

export function resolveDeploymentEnv(): DeploymentEnv {
	const isProd = window.location.hostname.includes('www.');
	return isProd ? 'prod' : 'stage';
}

export const deploymentEnv = resolveDeploymentEnv();

export const isProduction = deploymentEnv === 'prod';
