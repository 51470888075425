import { STORAGE_SESSION_ID_KEY } from '../constants';

function generateRandomId(): string {
	return Math.random().toString(36).slice(2, 20);
}

export function initializeSessionId(): string {
	const sessionId = sessionStorage.getItem(STORAGE_SESSION_ID_KEY);

	if (!sessionId) {
		sessionStorage.setItem(STORAGE_SESSION_ID_KEY, generateRandomId());
	}

	return sessionStorage.getItem(STORAGE_SESSION_ID_KEY) as string;
}

export function getSessionId() {
	return sessionStorage.getItem(STORAGE_SESSION_ID_KEY) as string;
}
