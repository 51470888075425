/**
 * The file gatsby-browser.tsx lets us respond to Gatsby-specific events within the browser, and wrap your page components in additional global components.
 * @see https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser
 */
import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact, { BugsnagPluginReactResult } from '@bugsnag/plugin-react';

import { initializeSessionId } from './src/utils/userSessionId';
import { deploymentEnv } from './src/config';
import './src/index.css';

const sessionId = initializeSessionId();

const isLocal = window.location.hostname === 'localhost';
const appEnv = isLocal ? 'local' : deploymentEnv;
const enabledReleaseStages = appEnv === 'local' ? [] : ['stage', 'canary', 'prod'];

Bugsnag.start({
	apiKey: process.env.GATSBY_BUGSNAG_API_KEY || 'BUGSNAG API KEY MISSING!',
	releaseStage: process.env.GATSBY_DEPLOY_ENVIRONMENT || appEnv,
	appVersion: __VERSION__,
	enabledReleaseStages, // comment out to test locally
	plugins: [new BugsnagPluginReact()],
	endpoints: {
		notify: 'https://bugsnag-notify.aspendental.com',
		sessions: 'https://bugsnag-sessions.aspendental.com',
	},
	onError: function (event) {
		event.addMetadata('user', {
			sessionId,
		});
	},
});

const ErrorBoundary = (Bugsnag.getPlugin('react') as BugsnagPluginReactResult).createErrorBoundary(React);

/**
 * This function is used to wrap the root element. This is useful to set up any Provider components that will wrap your application.
 * @param {ReactNode} element The “Page” React Element built by Gatsby
 */
export const wrapRootElement = ({ element }: { element: React.ReactNode }) => {
	// TODO: initialize contexts here
	//  https://aspendent.atlassian.net/wiki/spaces/TECH/pages/2337931318/Application+State+-+Standards+-+Web#Application-State
	return <ErrorBoundary>{element}</ErrorBoundary>;
};
