module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5D4DWG9","includeInDevelopment":true,"routeChangeEventName":"gatsby_page_view","enableWebVitalsTracking":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby","app_name":"wellnow-services","app_version":"0.0.7","ds_version":"1.28.2","build_date":"2023-05-12T15:05:10.786Z"}},"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"en","orientation":"any","display":"standalone","name":"Service Pages for WellNow.com","short_name":"wellnow-services","start_url":"/services","background_color":"#f7f0eb","theme_color":"#a2466c","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6328d6e6c164e6605dd84a1dc7c34836"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
